import type { PermissionType, StatusTimelineRowType } from "../types";
import { isAdmin } from "./helpers";

const StatusProtocolPermissions: PermissionType<StatusTimelineRowType> = {
  "status-protocol": {
    edit: (session, _resource) => isAdmin(session.user),
  },
};

export default StatusProtocolPermissions;
