import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";

import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";

import { isAdmin } from "../../abilities/helpers";
import { selectSession } from "../../App/sessionSlice";
import { useTitle } from "../../hooks";
import { LINES_QUERY } from "../../queries/lines";
import { CURRENT_CALL_STATE_QUERY } from "../../queries/pbx";
import type { CurrentCallStateDataType, LinesDataType } from "../../types";
import Loading from "../Loading";
import Line from "./Line";

export default function LineStatus() {
  const { user } = useSelector(selectSession);
  const [timer, setTimer] = useState(0);
  const { data: linesData } = useQuery<LinesDataType>(LINES_QUERY);
  const lineIds = linesData?.lines.map((line) => line.id) || [];

  if (isAdmin(user) && lineIds.length > 0) {
    lineIds.push("lobby");
  }

  const { data } = useQuery<CurrentCallStateDataType>(CURRENT_CALL_STATE_QUERY, {
    variables: { lineIds },
    skip: !lineIds.length,
  });

  useTitle("Status");

  useEffect(() => {
    const iv = window.setInterval(() => setTimer(timer + 1), 1000);

    return () => {
      window.clearInterval(iv);
    };
  });

  if (!linesData?.lines || !data?.currentCallState) return <Loading stretch />;

  return (
    <div className="ACD-call-state">
      {isAdmin(user) && (
        <Card className="ACD-call-state-line">
          <Card.Header>unzugeordnete Calls</Card.Header>

          <Card.Body>
            <Line lineId="lobby" callGroups={data.currentCallState.filter((callGroup) => callGroup.lineId === null)} />
          </Card.Body>
        </Card>
      )}

      {linesData.lines.map((line) => (
        <Card className="ACD-call-state-line" key={line.id}>
          <Card.Header>{line.name}</Card.Header>

          <Card.Body>
            <Line
              lineId={line.id}
              callGroups={data.currentCallState.filter((callGroup) => callGroup.lineId === line.id)}
            />
          </Card.Body>
        </Card>
      ))}
    </div>
  );
}
