import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { endOfDay, startOfDay } from "date-fns";

import type { RootState } from "../../store";
import type { CallDirectionType, Nullable } from "../../types";

export interface CallsState {
  start: Date;
  stop: Date;
  directions: CallDirectionType[];
  initiator: Nullable<string>;
  calledNo: Nullable<string>;
  connected: Nullable<boolean>;
  involvedPeer: Nullable<string>;
}

const initialState: CallsState = {
  start: startOfDay(new Date()),
  stop: endOfDay(new Date()),
  directions: ["INBOUND", "OUTBOUND"],
  initiator: null,
  calledNo: null,
  connected: null,
  involvedPeer: null,
};

export const callsSlice = createSlice({
  name: "calls",
  initialState,
  reducers: {
    setStart(state, { payload }: PayloadAction<Date>) {
      state.start = payload;
    },
    setStop(state, { payload }: PayloadAction<Date>) {
      state.stop = payload;
    },
    setDirections(state, { payload }: PayloadAction<CallDirectionType[]>) {
      state.directions = payload;
    },
    setInitiator(state, { payload }: PayloadAction<Nullable<string>>) {
      state.initiator = payload;
    },
    setCalledNo(state, { payload }: PayloadAction<Nullable<string>>) {
      state.calledNo = payload;
    },
    setConnected(state, { payload }: PayloadAction<Nullable<boolean>>) {
      state.connected = payload;
    },
    setInvolvedPeer(state, { payload }: PayloadAction<Nullable<string>>) {
      state.involvedPeer = payload;
    },
  },
});

export const { setStart, setStop, setDirections, setInitiator, setCalledNo, setConnected, setInvolvedPeer } =
  callsSlice.actions;
export const selectCalls = (state: RootState) => state.calls;
export default callsSlice.reducer;
