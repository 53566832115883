import { useEffect } from "react";

import { useApolloClient, useQuery } from "@apollo/client";

import { CURRENT_STATE_QUERY } from "../../queries/pbx";
import type { CurrentEndpointStateQueryType, EndpointStatus, EndpointStatusEvent } from "../../types";

export const classForEp = (state: EndpointStatus) => {
  let className = "";

  if (state.eventStatus && (!state.status || state.eventStatus === "ALERTING" || state.eventStatus === "TALKING")) {
    className = state.eventStatus;
  } else if (state.status) {
    className = state.status.stype;
  } else {
    className = "READY";
  }

  return `endpoint-entry ${className}`;
};

export default function StatusPanel() {
  const { data } = useQuery<CurrentEndpointStateQueryType>(CURRENT_STATE_QUERY);
  const client = useApolloClient();

  useEffect(() => {
    const updateState = (ev: EndpointStatusEvent) => {
      const msg = ev.detail;
      const currentEndpointState = client.cache.readQuery<CurrentEndpointStateQueryType>({
        query: CURRENT_STATE_QUERY,
      })?.currentEndpointState;

      if (!currentEndpointState) {
        return;
      }

      let endpoints;

      if (msg.type === "USER_DISAPPEARS") {
        endpoints = currentEndpointState.filter((ep) => ep.lastEvent?.e164 !== msg.lastEvent?.e164);
      } else {
        endpoints = [...currentEndpointState.filter((ep) => ep.lastEvent?.e164 !== msg.lastEvent?.e164), msg];
      }

      client.cache.writeQuery({ query: CURRENT_STATE_QUERY, data: { currentEndpointState: endpoints } });
    };
    window.addEventListener("acd:endpointStatus", updateState);

    return () => {
      window.removeEventListener("acd:endpointStatus", updateState);
    };
  });

  return (
    <ul className="ACD-status-panel">
      {[...(data?.currentEndpointState || [])]
        .sort((a, b) => (a.lastEvent?.cn || "").localeCompare(b.lastEvent?.cn || ""))
        .map((state, idx) => (
          <li key={state.lastEvent?.e164 || idx} className={classForEp(state)}>
            <span className="name">{state.lastEvent?.cn}</span>
            <span className="e164">{state.lastEvent?.e164}</span>
            {state.status && <span className="status">{state.status.name}</span>}
          </li>
        ))}
    </ul>
  );
}
