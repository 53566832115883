import { gql } from "@apollo/client";

export const STATUS_FRAGMENT = gql`
  fragment StatusFragment on Status {
    id
    name
    position
    stype
    warnAt
    archived
    insertedAt
    updatedAt
  }
`;

export const STATES_QUERY = gql`
  query states($limit: Int, $offset: Int, $archived: Boolean) {
    states(limit: $limit, offset: $offset, archived: $archived) {
      ...StatusFragment
    }
  }

  ${STATUS_FRAGMENT}
`;

export const COUNT_STATES_QUERY = gql`
  query countStates {
    countStates
  }
`;

export const STATUS_QUERY = gql`
  query status($id: ID!) {
    status(id: $id) {
      ...StatusFragment
    }
  }

  ${STATUS_FRAGMENT}
`;

export const DELETE_STATUS_MUTATION = gql`
  mutation deleteStatus($id: ID!) {
    deleteStatus(id: $id) {
      id
    }
  }
`;

export const STATUS_MUTATION = gql`
  mutation mutateStatus($id: ID, $status: StatusInput!) {
    mutateStatus(id: $id, status: $status) {
      ...StatusFragment
    }
  }

  ${STATUS_FRAGMENT}
`;

export const CHANGE_STATUS_MUTATION = gql`
  mutation changeStatus($id: ID!, $userId: ID, $no: String) {
    changeStatus(id: $id, userId: $userId, no: $no) {
      ...StatusFragment
    }
  }
  ${STATUS_FRAGMENT}
`;
