import type { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { getAuthorizationToken, removeAuthorizationToken, setAuthorizationToken } from "../authenticationToken";
import type { SubnavComponentType } from "../components/Layout/subnavComponents";
import { addErrorFlash } from "../features/Flash/flashSlice";
import { REFRESH_MUTATION } from "../queries/session";
import type { AppThunk, RootState } from "../store";
import type { Nullable, UserType } from "../types";

export interface SessionState {
  user: UserType;
  checked: boolean;
  loading: boolean;
  token: Nullable<string>;
  subSubnav: Nullable<SubnavComponentType>;
  socketConnected: boolean;
}

const initialState: SessionState = {
  user: null as unknown as UserType,
  checked: false,
  loading: false,
  token: null,
  subSubnav: null,
  socketConnected: false,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<Nullable<UserType>>) {
      state.user = action.payload as unknown as UserType;
      state.checked = true;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setToken(state, action: PayloadAction<string | null>) {
      state.token = action.payload;
    },
    setSubSubNav(state, action: PayloadAction<Nullable<SubnavComponentType>>) {
      state.subSubnav = action.payload;
    },
    setSocketConnected(state, { payload }: PayloadAction<boolean>) {
      state.socketConnected = payload;
    },
  },
});

export const { setLoading, setUser, setToken, setSubSubNav, setSocketConnected } = sessionSlice.actions;

export const selectSession = (state: RootState) => state.session;

export const refreshUser =
  (client: ApolloClient<NormalizedCacheObject>): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const { data } = await client.mutate({
        mutation: REFRESH_MUTATION,
        variables: {
          token: getAuthorizationToken(),
        },
      });

      dispatch(setUser(data.refresh.user));
      dispatch(setToken(data.refresh.token));

      setAuthorizationToken(data.refresh.token);
    } catch (e) {
      console.log(e);

      dispatch(setUser(null));
      removeAuthorizationToken();
      dispatch(addErrorFlash("Die Autorisierung ist fehlgeschlagen, bitte melden Sie sich neu an!"));
    }

    dispatch(setLoading(false));
  };

export const loginUser =
  (email: string, password: string, mutation: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const { data } = await mutation({ variables: { email, password } });
      setAuthorizationToken(data.signIn.token);

      dispatch(setUser(data.signIn.user));
      dispatch(setToken(data.signIn.token));
      dispatch(setLoading(false));
    } catch (e) {
      console.log(e);
      dispatch(setUser(null));
      dispatch(addErrorFlash("Benutzername oder Passwort sind falsch!", 5000));
      dispatch(setLoading(false));

      throw e;
    }
  };

export const logoutUser = (): AppThunk => async (dispatch) => {
  removeAuthorizationToken();
  dispatch(setUser(null));
  dispatch(setToken(null));
};

export default sessionSlice.reducer;
