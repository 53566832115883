import type { PermissionType } from "../types";
import { may } from "./";
import { isAdmin, isProjectAdmin, isSupervisor } from "./helpers";

const AdminPermissions: PermissionType<any> = {
  admin: {
    index: (session, resource) =>
      may(session, "admin/users", resource) ||
      may(session, "admin/endpoints", resource) ||
      may(session, "admin/lines", resource) ||
      may(session, "admin/queues", resource) ||
      may(session, "admin/projects", resource) ||
      may(session, "admin/states", resource) ||
      may(session, "admin/dashboards", resource) ||
      may(session, "admin/tokens", resource),
  },

  "admin/users": {
    index: ({ user }, _resource) => isProjectAdmin(user),
  },
  "admin/tokens": {
    index: ({ user }, _resource) => isAdmin(user),
  },
  "admin/endpoints": {
    index: ({ user }, _resource) => isProjectAdmin(user),
  },
  "admin/lines": {
    index: ({ user }, _resource) => isProjectAdmin(user),
  },
  "admin/queues": {
    index: ({ user }, _resource) => isSupervisor(user),
    priorities: ({ user }, _resource) => isSupervisor(user),
    create: ({ user }, _resource) => isProjectAdmin(user),
    edit: ({ user }, _resource) => isProjectAdmin(user),
    delete: ({ user }, _resource) => isProjectAdmin(user),
  },
  "admin/projects": {
    index: ({ user }, _resource) => isAdmin(user),
  },
  "admin/states": {
    index: ({ user }, _resource) => isAdmin(user),
  },
  "admin/dashboards": {
    index: ({ user }, _resource) => isProjectAdmin(user),
  },
  "admin/endpoint-audit-log": {
    index: ({ user }, _resource) => isAdmin(user),
  },
};

export default AdminPermissions;
