import { getIn } from "formik";
import type { MultiValue } from "react-select";

import type { OptionProp } from "./types";

export const fieldInvalid = (errors: Object, touched: Object, path: string) =>
  !!(getIn(errors, path) && getIn(touched, path));
export const fieldValid = (errors: Object, touched: Object, path: string) =>
  !!(!getIn(errors, path) && getIn(touched, path));

export const itemByValue = <T extends OptionProp>(value: unknown, options: T[]): T | undefined => {
  if (!value) {
    return undefined;
  }

  return options.find((o) => o.value === value);
};

export const itemsByValues = <T extends OptionProp>(value: unknown[], options: T[]): MultiValue<T> | undefined => {
  if (!value) {
    return undefined;
  }

  return value.map((val: unknown) => options.find((o) => o.value === val)).filter((v) => v !== undefined) as T[];
};
